<template>
	<div>
		
		<div class="Top">
			
			<div class="OrderTypes">
				<span>全部订单</span>
				<span>待收货</span>
				<span>已完成</span>
				<span class="On">退换/售后</span>
				<span>常购商品</span>
			</div>
			
			<div class="OrderSearch">
				
				<li>
					<el-select placeholder="请选择">
					    <el-option label="按采购单编号查询"></el-option>
						 <el-option label="按采购单名称查询"></el-option>
						  <el-option label="按平台订单号查询"></el-option>
						  <el-option label="按京东订单号查询"></el-option>
						  <el-option label="按苏宁订单号查询"></el-option>
						  <el-option label="按天猫订单号查询"></el-option>
						  <el-option label="按商品关键词查询"></el-option>
						  <el-option label="按订单状态查询"></el-option>
					  </el-select>
				</li>
				
				<li>
					<el-input></el-input>
				</li>
				
				<li>
					<el-button icon="el-icon-search">搜索订单</el-button>
				</li>
				
			</div>
			
		</div>
		
		<div class="TopTitle">
			<div class="C1">
				<el-dropdown>
				  <span class="el-dropdown-link">
				    近三个月订单<i class="el-icon-arrow-down el-icon--right"></i>
				  </span>
				  <el-dropdown-menu slot="dropdown">
				    <el-dropdown-item>近半年订单</el-dropdown-item>
				    <el-dropdown-item>全部订单</el-dropdown-item>
				  </el-dropdown-menu>
				</el-dropdown>
			</div>
			<div class="C2">数量</div>
			<div class="C3">小计</div>
			<div class="C4">收货人</div>
			<div class="C5">售后状态</div>
			<div class="C7"></div>
			
		</div>

		<div class="Table" v-for="k in 3" :key="k">
			
			<div class="Title" @click="$Jump('/my/order/detail')">
				<ul class="Info">
					2322323编号采购单:日用电器采购
					<span>已拆分</span>
				</ul>
				<ul class="OrderId">
					以通订单号:2121121212
				</ul>
			</div>
			
			<div class="Grid" v-for="item in 2" :key="item">
				
				<div class="C1">
					<div class="Left">
						<img src="http://img3.donews.com/uploads/adm/img_pic_1577453611.jpg" />
					</div>
					<div class="Right">
						华为MatePad Pro电脑模式体验 全新HUAWEI M-Pencil助力高效办公
						<li>苏宁 订单编号：121212121212</li>
					</div>
				</div>
				<div class="C2">10</div>
				<div class="C3">100</div>
				<div class="C4">王二狗</div>
				<div class="C5">换货中</div>
				<div class="C7">
					<span><i class="el-icon-delete"></i></span>
				</div>
				
			</div>
			
			
		</div>
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="1000">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination} from 'element-ui'
	export default {
	  name: 'MyOrderList',
	  props: {
	  },
	  data() {
	      return {
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination
	  },
	  methods:{
	  }
	}
</script>

<style scoped>
	.Top{
		display: flex;
		margin: 20px 20px 0px 20px;
		background-color: #FFFFFF;
		align-items: center;
		padding: 25px;
		
	}
	.Top .OrderTypes{
		flex: 1;
	}
	.OrderTypes span{
		margin-right: 15px;
		cursor: pointer;
	}
	.OrderTypes span.On{
		text-decoration: underline;
	}
	.Top .OrderSearch{
		display: flex;
	}
	.Top .OrderSearch li{
		margin-left: 10px;
	}
	
	.TopTitle{
		padding: 10px 25px;
		display: flex;
		color: #999999;
		font-size: 0.9rem;
		margin: 5px 20px 0px 20px;
	}
	
	.C1{
		flex: 1;
		display: flex;
		align-items: center;
	}
	.C1 .Left{
		width: 120px;
	}
	.C1 .Left img{
		width: 80px;
		height: 60px;
	}
	.C1 .Right{
		flex: 1;
	}
	.C1 .Right li{
		width: 100%;
		text-align: right;
		font-size: 0.9rem;
		color: rgba(0,0,0,0.3);
		margin-top: 10px;
	}
	.C2,.C3,.C4,.C5,.C6{
		width: 120px;
		text-align: center;
	}
	.C7{
		width: 50px;
		text-align: center;
	}
	.C7 span{
		padding: 5px 10px;
		cursor: pointer;
	}
	.C7 span:hover{
		color: rgba(228,0,0,1);
	}
	
	.Table{
		margin: 20px;
		padding: 20px;
		background-color: #FFFFFF;
	}
	.Table .Title{
		display: flex;
		margin-bottom: 15px;
		color: rgba(0,0,0,0.3);
		font-size: 0.9rem;
	}
	.Table .Title .Info{
		flex: 1;
	}
	.Table .Title .OrderId{
		width: 200px;
		text-align: right;
	}
	.Table .Grid{
		display: flex;
		padding: 10px 0px;
		border-top: 1px solid rgba(0,0,0,0.04);
		align-items: center;
	}
</style>
